import axios from "axios";
import useStore from "@/store";
import { ElMessage, ElMessageBox } from "element-plus";
import "element-plus/es/components/message-box/style/index";
import "element-plus/es/components/message/style/index";
import { getToken } from "@/utils/auth";
import router from "@/router/index.js";

let isRefreshPadding = true;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
const service = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API,
  timeout: 30000,
});
service.interceptors.request.use(
  (config) => {
    const { user } = useStore();
    config.headers.From = "crcm-cloud-admin";
    config.headers["Access-Control-Allow-Origin"] = "*";
    if (user.token) {
      config.headers[import.meta.env.VITE_APP_TOKEN_KEY] =
        "Bearer " + getToken();
    }
    if (config.method === "post") {
      config.data = {
        ...config.data,
        _t: Date.parse(new Date()) / 1000,
      };
    } else if (config.method === "get") {
      config.params = {
        _t: Date.parse(new Date()) / 1000,
        ...config.params,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    const { user } = useStore();
    const res = response.data;
    // 导出
    const headers = response.headers;
    if (
      headers["content-type"] &&
      (headers["content-type"].trim() ===
        "application/vnd.ms-excel;charset=UTF-8" ||
        headers["content-type"].trim() ===
          "application/vnd.ms-word;charset=UTF-8" ||
        headers["content-type"].trim() ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8")
    ) {
      return response;
    }
    if (response.config.responseType === "blob") {
      return response;
    }
    if (res.code !== 200) {
      if (isRefreshPadding) {
        isRefreshPadding = false;
        if (res.code == 401) {
          ElMessageBox.confirm(
            "登录已过期，您可以重新登录，也可以继续留在本页面",
            "系统提示",
            {
              confirmButtonText: "重新登录",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              user.logout().then((res) => {
                router.push("/");
                isRefreshPadding = true;
              });
              // location.reload()
            })
            .catch(() => {
              isRefreshPadding = true;
            });
        }
      }
      ElMessage({
        message: res.message || "error",
        type: "error",
        duration: 5 * 1000,
        grouping: true,
      });
      return Promise.reject(res.message || "error");
    } else {
      return res;
    }
  },
  (error) => {
    if (error && error.response) {
      //  1.判断请求超时
      if (
        error.code === "ECONNABORTED" &&
        error.message.indexOf("timeout") !== -1
      ) {
        error.message = "服务器连接超时，请稍后再试！";
        ElMessage({
          message: error,
          type: "error",
          duration: 5 * 1000,
          grouping: true,
        });
        return Promise.reject(error);
      }
      switch (error.response.status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          error.message = "未授权，请登录";
          break;
        case 403:
          error.message = "权限不足，操作被拒绝";
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器内部错误";
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
        default:
          break;
      }
    }
    ElMessage({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
      grouping: true,
    });
    return Promise.reject(error);
  }
);

export default service;
