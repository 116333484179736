import { defineStore } from "pinia";
import {
  loginByUsername,
  loginByPhoneCode,
  getInfo,
  logout,
} from "@/api/admin/user/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import Cookies from "@/plugin/cookies/cookie";
import { ElMessage } from "element-plus";
import { resolve } from "path-browserify";
import { getMemberInfoFun } from "@/api/user-center/member.js";
import { Messages } from "@/utils/message.js";
import request from "@/plugin/axios/request";

import OpenMerchantNumTip from "@/components/Dialog/OpenMerchantNumTip.js";

import router from "@/router";

const useUserStore = defineStore("user", {
  state: () => {
    return {
      exitDialog: false,
      token: getToken(),
      userId: Cookies.get("userId"),
      userName: Cookies.get("userName"),
      nickName: "", // Cookies.get('nickName'),
      supplierId: Cookies.get("supplierId") || "",
      roles: Cookies.get("roles") || [],
      memberInfo: null,
      supplierName: Cookies.get("supplierName"),
      authenticationStatus: Cookies.get("authenticationStatus"),
      notice: null,
      noticeData: [],
      noticeTimer: null,
      openTimer: false,
    };
  },
  actions: {
    login(userInfo, type) {
      if (type == "code") {
        //验证码登录
        return new Promise((resolve, reject) => {
          loginByPhoneCode(userInfo)
            .then((res) => {
              const { data } = res;
              setToken(data.access_token);
              this.token = data.access_token;
              this.getInfo();

              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else {
        //密码登录
        return new Promise((resolve, reject) => {
          loginByUsername(userInfo)
            .then((res) => {
              const { data } = res;
              setToken(data.access_token);
              this.token = data.access_token;
              this.getInfo();

              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getInfo() {
      if (!this.openTimer) {
        this.replaceOrder();
        this.openTimer = true;
      }

      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            const { data } = res;
            console.log("info--data", data);
            const {
              roles,
              username,
              avatar,
              menus,
              id,
              phone,
              nickName,
              supplierName,
              supplierId,
              authenticationStatus,
              citicUserId,
            } = data;
            Cookies.set("userId", id);
            Cookies.set("userName", username);
            Cookies.set("nickName", nickName);
            Cookies.set("roles", roles);
            Cookies.set("phone", phone);
            Cookies.set("avatar", avatar);
            Cookies.set("supplierName", supplierName);
            Cookies.set("authenticationStatus", authenticationStatus);
            supplierId && Cookies.set("supplierId", supplierId);
            this.userId = id;
            this.userName = username;
            this.roles = roles;
            this.phone = phone;
            this.nickName = nickName;
            this.supplierName = supplierName;
            this.supplierId = supplierId;
            this.authenticationStatus = authenticationStatus;
            if (!Window.IM) {
              Window.IM = new Messages(true, id);
            }

            if (this.supplierId && !citicUserId && !this.exitDialog) {
              this.exitDialog = true;
              OpenMerchantNumTip();
            }
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout() {
      return new Promise((resolve, reject) => {
        logout(this.token)
          .then((res) => {
            this.token = "";
            this.userName = "";
            this.userId = "";
            this.roles = "";
            this.supplierName = "";
            this.supplierId = "";
            removeToken();
            Cookies.remove("userId");
            Cookies.remove("userName");
            Cookies.remove("roles");
            Cookies.remove("supplierName");
            Cookies.remove("supplierId");
            this.notice && this.notice.close();
            this.openTimer = false;
            if (this.noticeTimer) {
              clearTimeout(this.noticeTimer);
              this.noticeTimer = null;
            }
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    resetToken() {
      return new Promise((resolve, reject) => {
        this.token = "";
        this.userName = "";
        this.menus = "";
        this.notice && this.notice.close();
        this.openTimer = false;
        if (this.noticeTimer) {
          clearTimeout(this.noticeTimer);
          this.noticeTimer = null;
        }
        removeToken();
        resolve();
      });
    },
    getMemberInfo() {
      return new Promise((resolve, reject) => {
        getMemberInfoFun()
          .then((res) => {
            this.memberInfo = res.data ? res.data : null;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    replaceOrder() {
      // 获取代下单信息
      request({
        url: "/admin/message/getAgentOrderMessageCount",
        method: "get",
      }).then((res) => {
        const notices = res.data;
        this.noticeData = res.data;
        if (notices.length && !this.notice) {
          this.notice = ElNotification({
            title: "消息提示",
            dangerouslyUseHTMLString: true,
            message: `您有${res.data.length}条代下单信息，请及时处理<br/><a style='color: #409EFF;cursor:pointer;'>点击跳转查看</a>`,
            type: "info",
            duration: 0,
            offset: 45,
            onClose: () => {
              this.notice = null;
            },
            onClick: () => {
              this.notice.close();
              // 设置已读
              const noticesAjax = notices.map((item) => {
                return request({
                  url: "/admin/message/readMessage",
                  method: "post",
                  data: {
                    id: item.id,
                  },
                });
              });

              Promise.all(noticesAjax).then((res) => {
                router.push({
                  path: "/user-center/order",
                });
                this.noticeTimer = setTimeout(() => {
                  this.replaceOrder();
                }, 2000);
              });
            },
          });
        } else {
          this.noticeTimer = setTimeout(() => {
            this.replaceOrder();
          }, 10000);
        }
      });
    },
  },
});

export default useUserStore;
